import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import EstateVisualContainer from "@/components/estates/visual/estateVisualContainer"


import wiz1 from "@/images/wiz1.jpg"
import wiz3 from "@/images/wiz3.jpg"
import wiz4 from "@/images/rzutjanowo2.jpg"
import wiz5 from "@/images/wiz5.jpg"
import wiz9 from "@/images/z-9/Lazienka2_V03.jpg"
import wiz10 from "@/images/z-9/Lazienka3_V03.jpg"

const Visual = ({ intl, parkings = false }) => {
  const sections = [
    {
      title: intl.formatMessage({ id: "offer.janowo.visual.title" }),
      items: [

        {
          title: intl.formatMessage({ id: "offer.janowo.visual.first.item.title" }),
          text: intl.formatMessage({
            id: "offer.janowo.visual.first.item.content",
          }),
          img: wiz1,
        },
        {
          title: intl.formatMessage({ id: "offer.janowo.visual.second.item.title" }),
          text: intl.formatMessage({
            id: "offer.janowo.visual.second.item.content",
          }),
          img: wiz3,
        },
        {
          title: intl.formatMessage({ id: "offer.janowo.visual.third.item.title" }),
          text: intl.formatMessage({
            id: "offer.janowo.visual.third.item.content",
          }),
          img: wiz5,
        },
        {
          title: intl.formatMessage({ id: "offer.janowo.visual.fourth.item.title" }),
          text: intl.formatMessage({
            id: "offer.janowo.visual.fourth.item.content",
          }),
          img: wiz4,
        }
      ]
    },

  ]

  if (!parkings) {
    sections.push({
      title: "Zmiany aranżacyjne i wykończenia pod klucz",
      items: [
        {
          text: `Wiemy, że wykończenie mieszkania często jest nie lada wyzwaniem, dlatego bazując na wieloletnim doświadczeniu naszych architektów i współpracy ze sprawdzonymi wykonawcami stworzyliśmy dział aranżacji i wykończeń pod klucz.<br><br>Jeśli potrzebujesz wprowadzić zmiany w układzie swojego mieszkania tak aby spełniało Twoje potrzeby, zaraz po zakupie lokalu, wspólnie z naszym architektem możesz dokonać niezbędnych poprawek.`,
          img: wiz9,
        },
        {
          text: `Możesz również skorzystać z pakietów wykończeniowych oszczędzając swój czas i pieniądze. To doskonałe rozwiązanie dzięki któremu zamieszkasz w pięknych i funkcjonalnych wnętrzach. Współpracując z naszym architektem stworzysz przestrzeń spełniającą Twoje oczekiwania.`,
          img: wiz10,
          imgStyle: { marginTop: "-42%" },
        },
      ],
    },)
  }

  return <EstateVisualContainer sections={sections} />
}
export default injectIntl(Visual)
