import React, { Fragment } from "react"
import EstateStatus from "../estateStatus"
import EstateTitle from "../estateTitle"
import EstateNav from "../estateNav"
import { HeaderContainer } from "./janowoStyle"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "gatsby-plugin-intl"
import logo from "@/images/z-20/logo_ojp.svg"

const JanowoHeader = ({ scrollDestination, intl, parkings = false }) => {
  const noPadding = { margin: "auto" }
  const desc = intl.formatMessage({
    id: "offer.janowo.content",
  })
  return (
    <Fragment>
      <HeaderContainer>
        <Container style={noPadding}>
          <Row>

            <Col
              className="content-wrapper"
              style={noPadding}
              xs="12"
              sm="12"
              md="6"
              lg="4"
            >
              <EstateTitle
                subTitleBold={intl.formatMessage({
                  id: "offer.janowo.content.bold",
                })}
                subtitle={desc}
              />
            </Col>
            <Col xs="12" sm="12" md="6" lg="4" className={"text-center"}>
              <img src={logo} alt={"Osiedle Janowo Park"} style={{maxWidth: '100%', height: '320px'}} />
            </Col>
            <Col className="header-nav" xs="0" sm="0" md="6" lg="4">
              <EstateNav scrollDestination={scrollDestination} parkings={parkings} />
            </Col>
            <Col className="desktop-info" xs="12">
              <div>
                10{" "}
                <span>
                  {intl.formatMessage({
                    id: "offer.janowo.info.buildings.name",
                  })}
                </span>
              </div>
              <div>
                186{" "}
                <span>
                  {intl.formatMessage({
                    id: "offer.janowo.info.flats.name",
                  })}
                </span>
              </div>
              <div>
                40-72 m<sup>2</sup>
                <span>
                  {" "}
                  {intl.formatMessage({
                    id: "offer.janowo.info.space.name",
                  })}
                </span>
              </div>
              <div>
                2020/2021
                <span>
                  {" "}
                  {intl.formatMessage({
                    id: "offer.janowo.info.sold.name",
                  })}
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </HeaderContainer>
      {/*<PlanVisual>
        <Container style={{ margin: 0, padding: 0 }} fluid>
          <Row>
            <Col style={{ margin: 0, padding: 0 }} xs="0" sm="0" md="12">
              <div className="plan-wiz"></div>
            </Col>
          </Row>
        </Container>
      </PlanVisual>*/}
    </Fragment>
  )
}

export default injectIntl(JanowoHeader)
