import React, { useState } from "react"
import { EstatePlanContainer } from "./janowoStyle.js"
import { Col, Container, Row } from "react-bootstrap"
import { Tooltip } from "reactstrap"
import EventListener from "react-event-listener"
import { Link as Scroller } from "react-scroll"
import { injectIntl } from "gatsby-plugin-intl"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import planImage from "@/images/z-8/z-7PZT.png"

function EstatePlan({ scrollDestination, intl }) {
  return (
    <>
      <EstatePlanContainer className="plan">
        <Container className="plan-title">
          <Row>
            <Col xs="12" className="no-padding">
              <h1>
                {intl.formatMessage({
                  id: "offer.janowo.plan.title",
                })}
              </h1>
            </Col>
          </Row>
        </Container>
        <Container style={{ marginBottom: "100px" }}>
          <Row>
            <Col className="no-padding" xs="12">
              <img
                src={planImage}
                alt={intl.formatMessage({
                  id: "offer.janowo.plan.title",
                })}
                style={{ maxWidth: "100%" }}
              />
            </Col>
          </Row>
        </Container>
      </EstatePlanContainer>
    </>
  )
}

export function TooltipItem({ item, i, handlePage, scrollDestination, intl }) {
  const [tooltipOpen, toggleTooltip] = useState(false)
  const [event, setEvent] = useState(false)

  const handleTooltipOpen = () => {
    toggleTooltip(true)
    setEvent(true)
  }

  const handleTooltipClose = () => {
    toggleTooltip(false)
    setEvent(false)
  }

  return (
    <>
      <div>
        <Tooltip
          trigger={"click"}
          isOpen={tooltipOpen}
          target={"Tooltip-" + i}
          toggle={() => handleTooltipOpen()}
          key={i}
          placement={item.placement}
          className="tooltip-style"
          hideArrow={false}
          arrowClassName={"tooltip-arrow"}
        >
          <span>{item.text}</span>
          <Scroller
            activeClass="active"
            to={"flats"}
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            {/* <button
            onClick={() =>
              handlePage(item.id)
              // `${item.text.replace(/\s/g, "")}`, "P0"
            }
          >
            Wyszukaj
          </button> */}
            {1 === 2 ? (
              <div
                style={{
                  color: "black",
                  fontSize: "16px",
                  fontWeight: 400,
                  margin: "20px 0",
                }}
              >
                {intl.formatMessage({
                  id: "offer.janowo.plan.inProgress",
                })}
              </div>
            ) : i === 4 ||
              i === 5 ||
              i === 6 ||
              i === 7 ||
              i === 8 ||
              i === 9 ||
              i === 0 ? (
              <div
                style={{
                  color: "black",
                  fontSize: "16px",
                  fontWeight: 400,
                  margin: "20px 0",
                }}
              >
                {intl.formatMessage({
                  id: "offer.janowo.plan.sold",
                })}
              </div>
            ) : (
              <>
                <button onClick={() => handlePage(`${item.id}`, "all")}>
                  {intl.formatMessage({
                    id: "offer.janowo.plan.floor.all",
                  })}
                </button>
                <button onClick={() => handlePage(`${item.id}`, "P3")}>
                  {intl.formatMessage({
                    id: "offer.janowo.plan.floor.fourth",
                  })}
                </button>
                <button onClick={() => handlePage(`${item.id}`, "P2")}>
                  {intl.formatMessage({
                    id: "offer.janowo.plan.floor.third",
                  })}
                </button>
                <button onClick={() => handlePage(`${item.id}`, "P1")}>
                  {intl.formatMessage({
                    id: "offer.janowo.plan.floor.second",
                  })}
                </button>
                <button onClick={() => handlePage(`${item.id}`, "P0")}>
                  {intl.formatMessage({
                    id: "offer.janowo.plan.floor.first",
                  })}
                </button>
              </>
            )}
          </Scroller>
        </Tooltip>
        {event && (
          <EventListener
            target={document}
            onClick={() => handleTooltipClose(false)}
          />
        )}
      </div>
    </>
  )
}

export default injectIntl(EstatePlan)
